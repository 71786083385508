<template>
  <CommonPage id="terms" title="Terms of services">
    <section id="terms-content">
      <div class="common-content-head">
        Welcome to dailytarot.org
      </div>
      <div class="common-content-row">
        Welcome to dailytarot.org! By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
      </div>
      <div class="common-content-head">
        <span>1</span> Acceptance of Terms
      </div>
      <div class="common-content-row">
        By using dailytarot.org, you agree to be legally bound by these terms. If you do not agree with any part of these terms, please do not use our website.
      </div>

      <div class="common-content-head">
        <span>2</span> AI-Generated Content
      </div>
      <div class="common-content-row">
        All tarot card readings and interpretations on dailytarot.org are generated by artificial intelligence. While we strive for accuracy and meaningful insights, the content should be considered for entertainment purposes only.
      </div>

      <div class="common-content-head">
        <span>3</span> User Conduct
      </div>
      <div class="common-content-row">
        You agree to use dailytarot.org for lawful purposes only. You must not use our website to engage in any activity that could harm or disrupt the site or other users.
      </div>

      <div class="common-content-head">
        <span>4</span> Privacy
      </div>
      <div class="common-content-row">
        Your use of dailytarot.org is also governed by our Privacy Policy, which can be found below.
      </div>

      <div class="common-content-head">
        <span>5</span> Limitation of Liability
      </div>
      <div class="common-content-row">
        dailytarot.org is provided "as is" without any warranties, express or implied. We do not guarantee the accuracy or reliability of AI-generated content. We are not liable for any damages arising from the use of our site.
      </div>

      <div class="common-content-head">
        <span>6</span> Changes to Terms
      </div>
      <div class="common-content-row">
        We reserve the right to modify these terms at any time. Your continued use of dailytarot.org constitutes acceptance of any changes.
      </div>

      <div class="common-content-head">
        <span>7</span> Contact Us
      </div>
      <div class="common-content-row">
        If you have any questions about these terms, please contact us at dailytarot.support@gmail.com.
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/m_common.vue';

import '@/css/common.scss';

export default {
  name: 'PrivacyMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
  }
}
</script>
